import "../styles/loading.sass";

export default function Loading() {
    return (
        <main>
            <div className="loader">Loading
                <span></span>
            </div>
        </main>
    );
}
